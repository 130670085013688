<template>
    <div
        id="list-ordersOriginsCodes-component"
        class="w-100 h-100 d-flex flex-column"
    >
        <label for="itemCurrent">Tipo de orden *</label>
        <vs-select
            v-if="enabled"
            v-model="option"
            shadow
            border
            name="itemCurrent"
            :state="stateInputDark"
            class="w-100 mw-100 border-custom"
            @input="onInput"
        >
            <vs-option
                v-for="(value, key) in $data.$ordersOriginsCodes"
                :key="key"
                :label="value"
                :value="key"
            >
                <span>
                    {{ value }} &nbsp;
                    <small> ({{ key }})</small>
                </span>
            </vs-option>
        </vs-select>
        <div v-else class="pl-2 pt-2">
            <span>
                {{ $data.$ordersOriginsCodes[itemCurrent] || "Portal" }} &nbsp;
                <small>({{ itemCurrent }})</small>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { ordersOriginsCodes } from "@/common/fields/orders-origins";

export default {
    name: "OrderOriginSelector",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => "pnd",
            type: String,
            require: true
        },
        enabled: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "",
        $ordersOriginsCodes: ordersOriginsCodes
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemCurrent(value) {
            this.option = value;
        }
    },
    mounted() {
        this.option = this.itemCurrent;
    },
    methods: {
        onInput() {
            this.$emit("change", this.option);
        }
    }
};
</script>
