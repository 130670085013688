<template>
    <div>
        <div class="d-flex justify-content-center w-100">
            <vs-tooltip bottom border>
                <vs-button icon circle @click="openNewFilter">
                    <img
                        src="@/assets/images/filter.svg"
                        height="15"
                        width="15"
                        alt="reload img"
                    />
                </vs-button>
                <template #tooltip> Modificar filtros de búsqueda </template>
            </vs-tooltip>
        </div>
        <div class="d-flex flex-column mt-1">
            <dates-range-filter
                v-if="activeFilters.includes('dateRange')"
                v-model="dateRange"
                @change="applyFilter"
            />
            <time-ago-filter
                v-if="activeFilters.includes('timeAgo')"
                v-model="timeAgo"
                @change="applyFilter"
            />

            <div class="d-flex flex-wrap w-100">
                <div
                    v-if="activeFilters.includes('uid')"
                    class="col-md-8 col-12 mx-auto"
                >
                    <barcode-scanner-input
                        v-model="uid"
                        enabled
                        @change="applyFilter"
                    />
                </div>
                <div
                    v-if="activeFilters.includes('deliveryStatus')"
                    class="col-md-8 col-12 mx-auto"
                >
                    <delivery-status-selector
                        v-model="deliveryStatus"
                        enabled
                        @change="applyFilter"
                    />
                </div>
                <div
                    v-if="activeFilters.includes('paymentStatus')"
                    class="col-md-8 col-12 mx-auto"
                >
                    <payment-status-selector
                        v-model="paymentStatus"
                        enabled
                        @change="applyFilter"
                    />
                </div>
                <div
                    v-if="activeFilters.includes('origin')"
                    class="col-md-8 col-12 mx-auto"
                >
                    <order-origin-selector
                        v-model="origin"
                        enabled
                        @change="applyFilter"
                    />
                </div>
                <div
                    v-if="activeFilters.includes('userId')"
                    class="col-md-8 col-12 mx-auto mt-2"
                >
                    <users-search v-model="user" enabled @input="applyFilter" />
                </div>
                <div
                    v-if="activeFilters.includes('sellerId')"
                    class="col-md-8 col-12 mx-auto mt-3"
                >
                    <user-by-merchant-selector
                        v-model="seller"
                        enabled
                        fill
                        label="Vendedor"
                        @change="applyFilter"
                    />
                </div>
            </div>

            <week-ago-filter
                v-if="activeFilters.includes('weekAgo')"
                v-model="weekAgo"
                @change="applyFilter"
            />
            <month-ago-filter
                v-if="activeFilters.includes('monthAgo')"
                v-model="mouthAgo"
                @change="applyFilter"
            />
        </div>

        <vs-dialog
            v-model="isOpenModal"
            scroll
            overflow-hidden
            auto-width
            @close="pushNewFilter"
        >
            <template #header>
                <div class="d-flex flex-column">
                    <div
                        class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                    >
                        <h3 class="text-center">Seleccione los filtros</h3>
                        <div>
                            <vs-button
                                :disabled="!filters.length"
                                icon
                                circle
                                @click="pushNewFilter"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <cards-selector v-model="filters" :base="baseFilters" />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { ordersFilters } from "@/common/fields/filters-query";

import DeliveryStatusSelector from "@/components/orders/DeliveryStatusSelector.vue";
import OrderOriginSelector from "@/components/orders/OrderOriginSelector.vue";
import PaymentStatusSelector from "@/components/orders/PaymentStatusSelector.vue";
import CardsSelector from "@/components/utils/CardsSelector.vue";

export default {
    name: "OrdersFilters",
    components: {
        CardsSelector,
        BarcodeScannerInput: () =>
            import("@/components/utils/BarcodeScannerInput.vue"),
        TimeAgoFilter: () => import("@/components/utils/TimeAgoFilter.vue"),
        UsersSearch: () => import("@/components/users/UsersSearch.vue"),
        UserByMerchantSelector: () =>
            import("@/components/users/UserByMerchantSelector.vue"),
        DatesRangeFilter: () =>
            import("@/components/utils/DatesRangeFilter.vue"),
        WeekAgoFilter: () => import("@/components/utils/WeekAgoFilter.vue"),
        MonthAgoFilter: () => import("@/components/utils/MonthAgoFilter.vue"),
        DeliveryStatusSelector,
        PaymentStatusSelector,
        OrderOriginSelector
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => ({}),
            type: Object,
            require: true
        },
        enabledFilters: {
            default: () => ["dateRange", "timeAgo"],
            type: Array,
            require: true
        }
    },
    data: () => ({
        activeFilters: [],
        uid: "",
        isOpenModal: false,
        dateRange: {},
        weekAgo: {},
        mouthAgo: {},
        timeAgo: { timeScale: "agoD", timeValue: 1 },
        user: { id: "", firstName: "", lastName: "" },
        seller: { id: "", firstName: "", lastName: "" },
        deliveryStatus: "",
        paymentStatus: "",
        origin: "",
        filters: [],
        baseFilters: []
    }),
    watch: {
        filters() {
            this.applyFilter();
        }
    },
    created() {
        this.baseFilters = ordersFilters.filter((item) =>
            this.enabledFilters.includes(item.id)
        );
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        setInput(value) {
            const filters = Object.keys(value);
            this.activeFilters = filters;
            this.filters = filters;
            if ("origin" in value) {
                this.origin = value.origin;
            }
            if ("deliveryStatus" in value) {
                this.deliveryStatus = value.deliveryStatus;
            }
            if ("paymentStatus" in value) {
                this.paymentStatus = value.paymentStatus;
            }
        },
        openNewFilter() {
            this.isOpenModal = true;
        },
        pushNewFilter() {
            this.activeFilters = [...this.filters];
            this.isOpenModal = false;
            this.applyFilter();
        },
        applyFilter() {
            this.$emit("change", {
                ...{
                    ...(this.activeFilters.includes("dateRange")
                        ? this.dateRange
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("timeAgo")
                        ? {
                              [this.timeAgo.timeScale]: this.timeAgo.timeValue
                          }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("weekAgo")
                        ? this.weekAgo
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("monthAgo")
                        ? this.mouthAgo
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("deliveryStatus") &&
                    this.deliveryStatus
                        ? { deliveryStatus: this.deliveryStatus }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("paymentStatus") &&
                    this.paymentStatus
                        ? { paymentStatus: this.paymentStatus }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("origin") && this.origin
                        ? { origin: this.origin }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("userId") && this.user.id
                        ? { sellerId: this.user.id }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("sellerId") &&
                    this.seller.id
                        ? { sellerId: this.seller.id }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("uid")
                        ? { uid: this.uid }
                        : null)
                }
            });
        }
    }
};
</script>
