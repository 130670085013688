import { ENABLED_ECOMMERCE_OPTIONS, SITE_TYPE } from "../constants";

export const productsFilters = [
    {
        id: "keyword",
        name: "Palabra clave",
        description: "Filtra por una palabra claves",
        icon: "🗝",
        conflicts: ["barcode"],
        show: true
    },
    {
        id: "active",
        name: "Activo",
        description: "Filtra si esta activo o desactivado",
        icon: "🟡",
        conflicts: ["barcode"],
        show: ENABLED_ECOMMERCE_OPTIONS
    },
    {
        id: "brand",
        name: "Marca",
        description: "Filtra entre las marcas registradas",
        scope: "",
        icon: "🔖",
        conflicts: ["barcode"],
        show: true
    },
    {
        id: "line",
        name: "Sublínea",
        description: "Filtra entre las sublíneas registradas",
        scope: "",
        icon: "➖",
        conflicts: ["barcode", "brand"],
        show: true
    },
    {
        id: "barcode",
        name: "Código de barrras",
        description: "Filtra por código de barras",
        icon: "📡",
        conflicts: ["line", "brand", "active", "keyword"],
        show: true
    }
];

export const ordersFilters = [
    {
        id: "uuid",
        name: "Código de barrras",
        description: "Filtra por código de barras",
        icon: "📡",
        conflicts: [
            "dateRange",
            "timeAgo",
            "weekAgo",
            "monthAgo",
            "deliveryStatus",
            "paymentStatus",
            "pickupStoreId",
            "sellerId",
            "type"
        ]
    },
    {
        id: "dateRange",
        name: "Rango de fechas",
        description: "Filtra entre dos fechas anteriores a la fecha actual",
        icon: "🗓",
        conflicts: ["timeAgo", "weekAgo", "monthAgo", "barcode"]
    },
    {
        id: "timeAgo",
        name: "Tiempo atras",
        description:
            "Filtra por un rango de tiempo a partir de la fecha actual",
        icon: "🕣",
        conflicts: ["dateRange", "weekAgo", "monthAgo", "barcode"]
    },
    {
        id: "weekAgo",
        name: "Semana en curso",
        description: "Filtra las ordenes de la semana en curso",
        icon: "🗓",
        conflicts: ["dateRange", "timeAgo", "monthAgo", "barcode"]
    },
    {
        id: "monthAgo",
        name: "Mes en curso",
        description: "Filtra las ordenes del més en curso",
        icon: "🗓",
        conflicts: ["dateRange", "timeAgo", "weekAgo", "barcode"]
    },
    {
        id: "type",
        name: "Tipo de orden",
        description: `Filtra por el tipo de orden, si es una orden por ${SITE_TYPE} o portal.`,
        icon: "🔱",
        conflicts: ["barcode"]
    },
    {
        id: "deliveryStatus",
        name: "Estado de entrega",
        description:
            "Filtra entre los estados de la entrega, este filtro de ayudará a dar prioridades y ver el estado de tu operación.",
        icon: "🚚",
        conflicts: ["barcode"]
    },
    {
        id: "paymentStatus",
        name: "Estado de pago",
        description:
            "Filtra entre los estados de la pago, este filtro de ayudará a dar prioridades y ver el estado pago de las ordenes.",
        icon: "💳",
        conflicts: ["barcode"]
    },
    {
        id: "userId",
        name: "Vendedor",
        description: "Filtra las ordenes por el usuario que realizó la venta.",
        icon: "👩‍💻",
        conflicts: ["sellerId", "barcode"]
    },
    {
        id: "sellerId",
        name: "Vendedor",
        description:
            "Filtra las ordenes por el usuario de tu tienda que realizó la venta.",
        icon: "👩‍💼",
        conflicts: ["userId", "barcode"]
    }
];

export const timeFilters = [
    {
        id: "dateRange",
        name: "Rango de fechas",
        description: "Filtra entre dos fechas anteriores a la fecha actual",
        conflicts: ["timeAgo"],
        icon: "🕣"
    },
    {
        id: "timeAgo",
        name: "Tiempo atras",
        description:
            "Filtra por un rango de tiempo a partir de la fecha actual",
        conflicts: ["dateRange"],
        icon: "🗓"
    }
];

export const linesFilters = [
    {
        id: "keyword",
        name: "Palabra clave",
        description: "Filtra por una palabra claves",
        icon: "🗝"
    },
    {
        id: "active",
        name: "Activo",
        description: "Filtra si esta activado o desactivado",
        icon: "🟢"
    },
    {
        id: "type",
        name: "Tipo",
        description: "Filtra si es línea o sublínea",
        icon: "〰"
    }
];

export const bannersFilters = [
    {
        id: "keyword",
        name: "Palabra clave",
        description: "Filtra por una palabra claves",
        icon: "🗝"
    },
    {
        id: "line",
        name: "Sublínea",
        description: "Filtra entre las sublíneas registradas",
        scope: "",
        icon: "➖"
    }
];

export const usersFilters = [
    {
        id: "email",
        name: "Correo",
        description: "Filtra por correo electrónico",
        conflicts: [],
        icon: "✉"
    },
    {
        id: "identificationNumber",
        name: "Número de indentificación",
        description: "Filtra por número de indentificación",
        conflicts: [],
        icon: "🚻"
    }
];
