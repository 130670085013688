<template>
    <div class="d-flex flex-wrap">
        <div
            v-for="(item, index) in base"
            v-show="!item.scope || $ability.hasScope(item.scope)"
            :key="index"
            class="col-12 col-lg-6 d-flex justify-content-center"
        >
            <button
                class="btn btn-primary my-2 text-left h-min-32 col-11"
                :class="
                    itemsCurrent.includes(item.id)
                        ? 'border-3 border-highlight'
                        : ''
                "
                @click="selectItem(item.id)"
            >
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <span class="h3">{{ item.name }}</span>
                    <span>{{ item.icon || "🔍" }}</span>
                </div>
                <p>{{ item.description }}</p>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardsSelector",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => [],
            type: Array,
            require: true
        },
        base: {
            default: () => [],
            type: Array,
            require: true
        }
    },
    emits: ["change"],
    data: () => ({
        selectedItems: []
    }),
    watch: {},
    methods: {
        selectItem(id) {
            const itemsCurrentTemp = [...this.itemsCurrent];
            const itemToPush = this.base.find((item) => item.id === id);
            if (itemToPush) {
                const fromIndex = itemsCurrentTemp.findIndex(
                    (item) => item === id
                );

                if (fromIndex < 0) {
                    itemsCurrentTemp.push(itemToPush.id);
                    if (
                        "conflicts" in itemToPush &&
                        itemToPush.conflicts.length
                    ) {
                        for (const item of itemToPush.conflicts) {
                            const fromIndexItem = itemsCurrentTemp.findIndex(
                                (item3) => item3 === item
                            );
                            if (fromIndexItem >= 0) {
                                itemsCurrentTemp.splice(fromIndexItem, 1);
                            }
                        }
                    }
                } else {
                    itemsCurrentTemp.splice(fromIndex, 1);
                }
                this.$emit("change", itemsCurrentTemp);
            }
        }
    }
};
</script>
